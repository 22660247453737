const Noodl = require('@noodl/noodl-sdk');
import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import {useDropzone} from 'react-dropzone';
import './style.css'
import axios from 'axios'

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    'font-family': 'Mulish-Regular',
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-block',
    borderRadius: 2,
    border: 'none',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position:'relative',
};

const thumbInner = {
    display: 'flex',
    width: 90,
    height: 70,
    overflow: 'hidden',
    margin: '0 auto'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    margin: '0 auto',
    filter: 'invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)'
};

const thumbText = {
    'font-size': '0.7em',
    color: '#979797',
    'text-align': 'center',
};

const abortStyle = {
    position: 'absolute',
    right: '17px',
    top: 0,
    'font-size': '0.6em',
    'text-transform': 'capitalize',
    'font-family': 'sans-serif',
    color: 'red',
    cursor: 'pointer',
    'z-index': '1',
}

function prodiktDropzone(props) {
    const [files, setFiles] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        // maxFiles: 10,
        // maxSize: 2500000,
        onDrop(droppedFiles) {
            setFiles([...files, ...droppedFiles.map(file => ({
                objectId: null,
                name: file.name,
                deleting: false,
                uploading: true,
            }))])

            droppedFiles.map(file => {
                const formData = new FormData()
                formData.append('file', file);
                props.formDataOutput(formData)
            })
          }
    });

    const elemRef = useRef();

    const shortImgName = imgName => {
        if (!imgName) return ''
        return (imgName || '').split('.')[0].slice(0, 10) + '...' + (imgName || '').split('.')[1]
    }

    const deleteFile = (objectId, event) => {
        const index = files.indexOf(files.find(o => o.objectId === objectId))

        if (!files[index])
            return false

        files[index].deleting = true
        setFiles(files)
        props.fileIdToDelete(files[index].objectId)
        props.onDeleteFileClick()

        event.stopPropagation();
        elemRef.current.blur();
    }

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <span style={abortStyle} className='abort' onClick={deleteFile.bind(this, file.objectId)}>{file.uploading || file.deleting ? '' : 'x'}</span>
            <div style={thumbInner}>
                <img
                    className={(file.uploading || file.deleting ? 'uploading pulse' : '')}
                    src='https://s3.eu-central-1.amazonaws.com/app-dev.prodikt.com/Assets/icons/certificate.png'
                    style={img}
                />
            </div>
            <p className={(file.uploading || file.deleting ? 'spinner' : '')} style={thumbText}>{file.uploading || file.deleting ? '' : shortImgName(file.name)}</p>
        </div>
    ));

    useEffect(() => {
        if (!props.doneUploading?.name) return

        const index = files.indexOf(files.find(o => o.name === props.doneUploading.name && o.uploading === true))

        if (index === -1) return
        const newFiles = files

        //objectid missing - upload failed
        if (!props.doneUploading.objectId) {
            newFiles.splice(index, 1)
            console.log('Failed uploading ProjectFile - removing temp file..')
            props.onUploadFailed()
        } else {
            newFiles[index].objectId = props.doneUploading.objectId
            newFiles[index].uploading = false
        }

        setFiles(newFiles)
        elemRef.current.focus();
        elemRef.current.blur();
    }, [props.doneUploading])

    useEffect(() => {
        if (!props.deletedFile) return

        const index = files.indexOf(files.find(o => o.objectId === props.deletedFile.data.objectId))
        files.splice(index, 1)
        setFiles(files)

        elemRef.current.focus();
        elemRef.current.blur();
    }, [props.deletedFile])

    useEffect(() => {
        if (props.inputFiles?.length) {
            setFiles(props.inputFiles.map(file => file.data))
        }
    }, [props.inputFiles])

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        position: 'relative',
        border: '2px dashed #d0d0d0',
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const dropzoneDiv = <div {...getRootProps({style})} ref={elemRef}>
            <input {...getInputProps()} />
            <p style={{ margin: 'auto', position: 'absolute', top: '2px', left: '4px' }}>{props.text}</p>
            <div className="imageWrapper">
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>
            </div>
        </div>
        const filePreviews = <div className="imageWrapper">
    </div>

    return (
        <div className="dropzoneWrapper container">
            {dropzoneDiv}
            {filePreviews}
        </div>
    );
}

const prodiktDropzoneNode = Noodl.defineReactNode({
	name: 'Prodikt dropzone component',
	category: 'Component',
	getReactComponent() {
		return prodiktDropzone;
	},
	inputProps: {
		backgroundColor: {type: 'color', default: 'white'},
		marginBottom: {type: {name: 'number', units: ['px'], defaultUnit: 'px'}, default: 10},
        text: {type: 'string', default: 'Drag \'n\' drop files here, or click to select files.'},
        buttonText: {type: 'string', default: 'Upload'},
        inputFiles: {type: 'array'},
        doneUploading: {type: 'object'},
        deletedFile: {type: 'object'},
        fileDeleted: {type: 'signal'},
    },
	outputProps: {
		onDeleteFileClick: {type: 'signal', displayName: 'onDeleteFileClick'},
		onUploadFile: {type: 'signal', displayName: 'onUploadFile'},
        outputFiles: {type: 'array'},
        fileIdToDelete: {type: 'string'},
        formDataOutput: {type: 'object'},
        onUploadFailed: {type: 'signal', displayName: 'onUploadFailed'},
    },
})


Noodl.defineModule({
    reactNodes: [
    	prodiktDropzoneNode
    ],
    nodes:[
    ],
    setup() {
    	//this is called once on startup
    }
});